import api from '../tools/api';

export const getIncomingRequests = (page_number, select_values) => api.request(
    'incoming-requests',
    'get-incoming-requests',
    {page_number, select_values}
);

export const getCountIncomingRequests = (select_values) => api.request(
    'incoming-requests',
    'get-count-incoming-requests',
    {select_values}
);
