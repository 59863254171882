<template src="./templates/requests.html"></template>

<script>
    import {
        getIncomingRequests,
        getCountIncomingRequests
    } from '@api/incoming-requests';
    import '../../layouts/default/styles/orders.scss';
    import Grid from '@c/Grid';

    export default {
        name: 'IncomingRequests',
        components: {
            Grid
        },
        props: {
            size: {
                type: Number,
                required: false,
                default: 50
            }
        },
        data() {
            return {
                requests: null,
                statusFilters: {statuses: [], closeStatuses: []},
                pageNumber: 0,
                allPages: 0,
                countIR: 0,
                selectionValues: {
                    test: null,
                },
            };
        },
        async created() {
            await this.compileData(this.pageNumber * 50);
        },
        methods: {
            async nextPage() {
                this.pageNumber++;
                await this.compileData(this.pageNumber * 50);
            },
            async prevPage() {
                this.pageNumber--;
                await this.compileData(this.pageNumber * 50);
            },
            async openPage(key) {
                this.pageNumber = key;
                await this.compileData(this.pageNumber * 50);
            },
            async compileData(offset) {
                this.requests = await getIncomingRequests(offset, this.selectionValues);
                this.countIR = await getCountIncomingRequests(this.selectionValues);
                this.allPages = Math.ceil(this.countIR / 50);
            },
        }
    };
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
