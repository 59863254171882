<div class="container">
    <div>
        <Grid
                :columns="[
                {
                    name: 'lead_id',
                    label: '№ВО-амо',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'region_name',
                    label: 'Регион',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'direction_name',
                    label: 'Товарное направление',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'contact_name',
                    label: 'Контакт',
                    width: 'minmax(150px, 3.33fr)',
                },
                {
                    name: 'contact_data',
                    label: 'Контактные данные',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'time_left',
                    label: 'Минут прошло',
                    width: 'minmax(100px, 3.33fr)',
                },
                {
                    name: 'comment',
                    label: 'Примечание для менеджера',
                    width: 'minmax(150px, 3.33fr)',
                },
            ]"
                :data="requests"
        ></Grid>
    </div>
    <div class="pagination__wrapper">
        <ul class="pagination">
            <li>
                <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>
            </li>
            <template v-for="(number,key) in allPages">
                <li @click="key !== pageNumber ? openPage(key) : ''"
                    v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">
                    <button
                        v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">
                        {{ number }}
                    </button>
                </li>
            </template>
            <li>
                <button class="next" @click="nextPage" :disabled="pageNumber">&#10095;</button>
            </li>
        </ul>
    </div>
</div>
